/***
 * This code is for fetching or recivig data from api
 */
import axios from "axios";
import { API_URL } from "../config/config";
import { store } from "../redux/store";

const locationdata = store.getState().Location;

// variable that we require in formdata
const lat = locationdata.lat;
const lng = locationdata.lng;
var formdata = new FormData();
formdata.append("latitude", lat);
formdata.append("longitude", lng);

// const get_stored_token = () => {
//   const state = store.getState();
//   return state.UserData.profile.token;
// };

const get_stored_token = async () => {
  const state = store.getState();
  const token = state.UserData?.profile?.token;
  // const myHeaders = new Headers();
  if (token) {
    // myHeaders.append("Authorization", `Bearer ${token}`);
    return token;
  } else {
    // Token not available in the Redux store, return null or a placeholder value
    return null;
  }
};

// axios interceptors
const api = axios.create({
  baseURL: API_URL,
});

api.interceptors.request.use(
  async function (config) {
    const token = await get_stored_token(); // Get the token directly
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const get_home_screen = async (latitude, longitude) => {
  try {
    const token = await get_stored_token();
    if (token === undefined) {
      // Token not available, handle this case appropriately
      // (e.g., show a loading indicator, redirect to the login page)
      return null;
    }

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append("latitude", latitude);
    formdata.append("longitude", longitude);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    const response = await fetch(
      `${API_URL}get_home_screen_data`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

/* function for fetching Category  */
const get_category = async ({
  latitude = "",
  longitude = "",
  search = null,
}) => {
  try {
    var formdata = new FormData();
    formdata.append("latitude", latitude);
    formdata.append("longitude", longitude);

    if (search || search !== null) {
      formdata.append("search", search);
    }

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    const response = await fetch(`${API_URL}get_categories`, requestOptions);

    if (!response.ok) {
      throw new Error("Failed to fetch categories");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    // Handle the error here
    console.error("Error fetching categories:", error);
    return null; // You can return a default value or handle the error as needed
  }
};

// function for fetching all providers
// async function get_providers(
//   latitude = "",
//   longitude = "",
//   id = "",
//   search = "",
//   category_id = 0,
//   subcategory_id = 0,
//   order = "asc",
//   filter = null,
// ) {
//   return new Promise(async function (resolve, reject) {
//     var formdata = new FormData();
//     formdata.append("latitude", latitude);
//     formdata.append("longitude", longitude);
//     if (id > 0) {
//       formdata.append("partner_id", id);
//     }
//     if (search || search !== null) {
//       formdata.append("search", search);
//     }
//     if (category_id > 0) {
//       formdata.append("category_id", category_id);
//     }
//     if (subcategory_id > 0) {
//       formdata.append("subcategory_id", subcategory_id);
//     }
//     formdata.append("order", order);
//     if (filter || filter !== null) {
//       formdata.append("filter", filter);
//     }
//     // Use When Categories are available

//     var requestOptions = {
//       method: "POST",
//       body: formdata,
//       redirect: "follow",
//     };

//     try {
//       const response = await fetch(`${API_URL}/get_providers`, requestOptions);
//       if (!response.ok) {
//         reject();
//       }
//       resolve(response.json());
//     } catch (error) {
//       console.log(error);
//       reject();
//     }
//   });
// }

async function get_providers({
  latitude = "",
  longitude = "",
  id = "",
  search = "",
  category_id = 0,
  subcategory_id = 0,
  order = "asc",
  filter = null,
}) {
  return new Promise(async function (resolve, reject) {
    const formdata = new FormData();
    formdata.append("latitude", latitude);
    formdata.append("longitude", longitude);
    if (id > 0) {
      formdata.append("partner_id", id);
    }
    if (search !== "") {
      // Simplified condition
      formdata.append("search", search);
    }
    if (category_id > 0) {
      formdata.append("category_id", category_id);
    }
    if (subcategory_id > 0) {
      formdata.append("subcategory_id", subcategory_id);
    }
    formdata.append("order", order);
    if (filter !== null) {
      // Simplified condition
      formdata.append("filter", filter);
    }

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(`${API_URL}get_providers`, requestOptions);
      if (!response.ok) {
        throw new Error("Failed to fetch providers"); // Throw custom error
      }
      resolve(response.json());
    } catch (error) {
      console.error("Error fetching providers:", error); // Log error
      reject(error); // Pass error to the caller
    }
  });
}

// function for fetching services
async function get_services() {
  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };
  try {
    const response = await fetch(`${API_URL}/get_services`, requestOptions);
    if (!response.ok) {
      return 0;
    }
    return response.json();
  } catch (error) {
    console.log(error);
  }
}

// function for fetching some default settings
async function get_settings() {
  var requestOptions = {
    method: "POST",
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_URL}get_settings`, requestOptions);
    if (!response.ok) {
      return 0;
    }
    return response.json();
  } catch (error) {
    console.log(error);
  }
}

// function for fetching default bookmarks

const get_bookmarks = async (bookmark) => {
  try {
    const token = await get_stored_token();
    if (token === undefined) {
      return null;
    }

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: bookmark,
      redirect: "follow",
    };

    const response = await fetch(`${API_URL}book_mark`, requestOptions);

    return await response.json();
  } catch (error) {
    console.error("Error fetching bookmarks:", error);
    return null;
  }
};

// function for fetching cart items
const get_cart = async () => {
  try {
    const token = await get_stored_token();

    if (token === undefined) {
      return null;
    }

    const requestOptions = {
      method: "POST",
      redirect: "follow",
      body: "",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${API_URL}get_cart`, requestOptions);

    if (!response.ok) {
      return 0;
    }

    const responseData = await response.json();
    responseData.data = responseData.data?.cart_data?.data || [];

    return responseData;
  } catch (error) {
    console.error(error);
  }
};

async function get_cart_plain() {
  const token = await get_stored_token();

  if (token === undefined) {
    return null;
  }
  var requestOptions = {
    method: "POST",
    redirect: "follow",
    body: "",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}get_cart`, requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          response.data = response.data?.cart_data;
          response.data = response.data || [];
          resolve(response);
        });
    });

    // return response.json();
  } catch (error) {
    console.log(error);
  }
}

//function for check is provider avilable for this location
const providerAvailable = async ({
  latitude = "",
  longitude = "",
  isCheckout = 0,
}) => {
  try {
    const token = await get_stored_token();

    if (token === undefined) {
      return null;
    }

    var formdata = new FormData();
    formdata.append("latitude", latitude);
    formdata.append("longitude", longitude);
    formdata.append("is_checkout_process", isCheckout);

    var requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
      redirect: "follow",
    };

    const response = await fetch(
      `${API_URL}provider_check_availability`,
      requestOptions
    );
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

//function to add address
const AddAddress = async (
  id = "",
  mobile = "",
  address = "",
  city_id = 0,
  city_name = "",
  latitude = "",
  longitude = "",
  area = "",
  type = "",
  country_code = "",
  pincode = "",
  state = "",
  country = "",
  is_default = "",
  landmark = ""
) => {
  var formdata = new FormData();
  if (id !== null) {
    formdata.append("address_id", id);
  }
  formdata.append("mobile", mobile);
  formdata.append("address", address);
  formdata.append("city_id", city_id);
  formdata.append("city_name", city_name);
  formdata.append("lattitude", latitude);
  formdata.append("longitude", longitude);
  formdata.append("area", area);
  formdata.append("type", type);
  formdata.append("country_code", country_code);
  formdata.append("pincode", pincode);
  formdata.append("state", state);
  formdata.append("country", country);
  formdata.append("is_default", is_default);
  formdata.append("landmark", landmark);
  formdata.append("alternate_mobile", mobile);

  const token = await get_stored_token();

  if (token === undefined) {
    return null;
  }
  var requestOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_URL}/add_address`, requestOptions);
    if (!response.ok) {
      return 0;
    }
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

//function to check available slots
const checkSlots = async (partner_id = "", date = "", time = "") => {
  var formdata = new FormData();
  formdata.append("partner_id", partner_id);
  formdata.append("date", date);
  formdata.append("time", time);

  const token = await get_stored_token();

  if (token === undefined) {
    return null;
  }
  var requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${API_URL}/check_available_slot`,
      requestOptions
    );
    if (!response.ok) {
      return 0;
    }
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

// to place order
const plaecOrder = async (
  method = "",
  date = "",
  time = "",
  addressId = 0,
  order_note,
  promoCode = ""
) => {
  const state = store.getState();
  var formdata = new FormData();
  formdata.append("payment_method", method);
  // if delivery mode is home than only address id passed otherwise not
  state?.DeliveryAddress?.deliveryType === "Home"
    ? formdata.append("address_id", addressId)
    : formdata.append("address_id", "");

  formdata.append("status", "awaiting");
  if (order_note) formdata.append("order_note", order_note);
  formdata.append("date_of_service", date);
  formdata.append("starting_time", time);
  if (promoCode) formdata.append("promo_code", promoCode);
  formdata.append(
    "at_store",
    state?.DeliveryAddress?.deliveryType === "Home" ? 0 : 1
  );
  const token = await get_stored_token();

  if (token === undefined) {
    return null;
  }

  var requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_URL}/place_order`, requestOptions);
    if (!response.ok) {
      return 0;
    }
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

// create razorpay order
const createRazorOrder = async (orderId = "") => {
  var formdata = new FormData();
  formdata.append("order_id", orderId);
  const token = await get_stored_token();
  if (token === undefined) {
    return null;
  }
  var requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${API_URL}/razorpay_create_order`,
      requestOptions
    );
    if (!response.ok) {
      return 0;
    }
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

// to get awailable slots
const get_available_slot = async (partner_id = 0, selectedDate = Date()) => {
  const MyForm = new FormData();
  MyForm.append("partner_id", partner_id);
  MyForm.append("date", selectedDate);
  const token = await get_stored_token();
  if (token === undefined) {
    return null;
  }
  var requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: MyForm,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      API_URL + "get_available_slots",
      requestOptions
    );
    if (!response.ok) {
      return 0;
    }
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

// for manage cart and update cart
const ManageCart = async (id = 0, qty = 0) => {
  var formdata = new FormData();
  formdata.append("service_id", id);
  formdata.append("qty", qty);
  const token = await get_stored_token();
  if (token === undefined) {
    return null;
  }
  var requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_URL}/manage_cart`, requestOptions);

    if (!response.ok) {
      return 0;
    }
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

// for fetching particular provider services
const allServices = async (partner_id, company_name) => {
  var formdata = new FormData();
  formdata.append("latitude", lat);
  formdata.append("longitude", lng);
  formdata.append("partner_id", `${partner_id}`);
  formdata.append("company_name", `${company_name}`);
  formdata.append("limit", 500);

  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_URL}/get_services`, requestOptions);

    if (!response.ok) {
      return 0;
    }
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

const Promocode = async (partner_id = 0) => {
  var formdata = new FormData();
  formdata.append("partner_id", partner_id);
  const token = await get_stored_token();
  if (token === undefined) {
    return null;
  }
  var requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_URL}/get_promo_codes`, requestOptions);

    if (!response.ok) {
      return 0;
    }
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

const ValidatePromocode = async (provider_id, promo_code, overall_amount) => {
  const token = await get_stored_token();
  if (token === undefined) {
    return null;
  }

  var formdata = new FormData();
  formdata.append("partner_id", provider_id);
  formdata.append("promo_code", promo_code);
  formdata.append("final_total", overall_amount);

  var requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${API_URL}/validate_promo_code`,
      requestOptions
    );

    if (!response.ok) {
      return 0;
    }
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

const VerifyUser = async (phone = "", country_code = +91) => {
  var verifyUser = new FormData();
  verifyUser.append("mobile", phone);
  verifyUser.append("country_code", country_code);

  var requestOptions = {
    method: "POST",
    body: verifyUser,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_URL}verify_user`, requestOptions);
    if (!response.ok) {
      return 0;
    }
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

const getToken = async (phone = "", country = +91, web_fcm_id = "") => {
  var formdata = new FormData();
  formdata.append("mobile", phone);
  formdata.append("country_code", country);
  formdata.append("web_fcm_id", web_fcm_id);

  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_URL}/manage_user`, requestOptions);

    if (!response.ok) {
      return 0;
    }
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

const DeleteAddress = async (address_id = 0) => {
  const formData = new FormData();
  formData.append("address_id", address_id);
  const token = await get_stored_token();
  if (token === undefined) {
    return null;
  }
  var requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_URL}delete_address`, requestOptions);

    if (!response.ok) {
      return 0;
    }
    return response.json();
  } catch (error) {
    console.log(error);
  }

  fetch(`${API_URL}/delete_address`, requestOptions)
    .then((response) => {
      if (!response.ok) {
        return 0;
      }
      return response.json();
    })
    .catch((error) => {
      console.log(error);
      throw error; // Propagate the error further if needed
    });
};

const getAddress = async () => {
  const token = await get_stored_token();
  if (token === undefined) {
    return null;
  }

  var requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_URL}/get_address`, requestOptions);

    if (!response.ok) {
      return 0;
    }

    return response.json();
  } catch (error) {
    console.log(error);
  }
};

const bookmark = async (type = "", lat = "", lng = "", partner_id = "") => {
  const token = await get_stored_token();
  if (token === undefined) {
    return null;
  }

  var formdata = new FormData();
  formdata.append("type", type);
  formdata.append("latitude", lat);
  formdata.append("longitude", lng);
  if (partner_id) {
    formdata.append("partner_id", partner_id);
  }

  var requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_URL}/book_mark`, requestOptions);
    if (!response.ok) {
      return 0;
    }
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

const manageUser = async (
  contact = "",
  CountryCode = "",
  Myname = "",
  email = "",
  web_fcm_id = ""
) => {
  var formdata = new FormData();
  formdata.append("mobile", contact);
  formdata.append("country_code", CountryCode);
  formdata.append("username", Myname);
  formdata.append("email", email);
  formdata.append("web_fcm_id", web_fcm_id);

  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_URL}/manage_user`, requestOptions);

    if (!response.ok) {
      return 0;
    }
    return response.json();
  } catch (error) {}
};

const update_user = async (
  contact = "",
  Myname = "",
  email = "",
  profileImage = null
) => {
  var formdata = new FormData();
  formdata.append("mobile", contact);
  formdata.append("username", Myname);
  formdata.append("email", email);

  if (profileImage != null) {
    formdata.append("image", profileImage);
  }

  const token = await get_stored_token();
  if (token === undefined) {
    return null;
  }

  var requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_URL}update_user`, requestOptions);

    if (!response.ok) {
      return 0;
    }
    return response.json();
  } catch (error) {}
};

const getSubCategory = async (
  latitude = "",
  longitude = "",
  category_id = "",
  title = ""
) => {
  var formdata = new FormData();
  formdata.append("latitude", latitude);
  formdata.append("longitude", longitude);
  formdata.append("category_id", category_id);
  formdata.append("title", title);

  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${API_URL}/get_sub_categories`,
      requestOptions
    );

    if (!response.ok) {
      return 0;
    }
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

const deleteUserAccount = async () => {
  const token = await get_stored_token();
  if (token === undefined) {
    return null;
  }

  var requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${API_URL}delete_user_account`,
      requestOptions
    );

    if (!response.ok) {
      return 0;
    }

    return response.json();
  } catch (error) {
    console.log(error);
  }
};

const logout = async () => {
  const token = await get_stored_token();
  if (token === undefined) {
    return null;
  }

  var formdata = new FormData();
  formdata.append("all_device", "true");

  var requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_URL}/logout`, requestOptions);

    if (!response.ok) {
      return 0;
    }
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

const getOrders = async (
  id = "",
  currentPage = "",
  itemsPerPage = "",
  status = "",
  order_statuses = ""
) => {
  const token = await get_stored_token();
  if (token === undefined) {
    return null;
  }

  var formdata = new FormData();
  if (order_statuses.includes(status)) {
    formdata.append("status", status);
  }
  if (id) {
    formdata.append("id", id);
  }

  var requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formdata,
    redirect: "follow",
  };
  try {
    const response = await fetch(
      `${API_URL}get_orders?offset=${
        currentPage * itemsPerPage
      }&limit=${itemsPerPage}`,
      requestOptions
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
    throw error; // Propagate the error further if needed
  }
};

const userNotifications = async (limit = "", offset = "") => {
  const token = await get_stored_token();
  if (token === undefined) {
    return null;
  }

  var formdata = new FormData();
  formdata.append("limit", limit);
  formdata.append("offset", offset);

  var requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${API_URL}/get_notifications`,
      requestOptions
    );

    if (!response.ok) {
      return 0;
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
    throw error; // Propagate the error further if needed
  }
};

const getTransaction = async (limit = "", offset = 0) => {
  const token = await get_stored_token();
  if (token === undefined) {
    return null;
  }
  var formdata = new FormData();

  formdata.append("limit", limit ?? "10");

  var requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_URL}/get_transactions`, requestOptions);

    return response.json();
  } catch (error) {
    console.log(error);
  }
};

const removeCart = async (itemId) => {
  const token = await get_stored_token();
  if (token === undefined) {
    return null;
  }

  var formdata = new FormData();
  formdata.append("service_id", itemId);

  var requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_URL}/remove_from_cart`, requestOptions);

    if (!response.ok) {
      return 0;
    }

    return response.json();
  } catch (error) {
    console.log(error);
  }
};

const getRating = async (partnerID, limit = 5, offset = 0) => {
  const token = await get_stored_token();
  if (token === undefined) {
    return null;
  }

  var formdata = new FormData();
  formdata.append("partner_id", partnerID);
  formdata.append("limit", limit);
  formdata.append("offset", offset);

  var requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_URL}/get_ratings`, requestOptions);

    if (!response.ok) {
      return 0;
    }
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

const getServiceRating = async (
  partner_id,
  serviceID,
  limit = 5,
  offset = 0
) => {
  const token = await get_stored_token();
  if (token === undefined) {
    return null;
  }

  var formdata = new FormData();
  formdata.append("partner_id", serviceID);
  formdata.append("service_id", serviceID);
  formdata.append("limit", limit);
  formdata.append("offset", offset);

  var requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_URL}/get_ratings`, requestOptions);

    if (!response.ok) {
      return 0;
    }
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

const add_transactions = async (orderID, status) => {
  const token = await get_stored_token();
  if (token === undefined) {
    return null;
  }

  var formdata = new FormData();
  formdata.append("order_id", orderID);
  formdata.append("status", status);

  var requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(API_URL + "add_transaction", requestOptions);

    if (!response.ok) {
      return 0;
    }
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

const send_message = async (
  name = "",
  subject = "",
  message = "",
  email = ""
) => {
  const token = await get_stored_token();
  if (token === undefined) {
    return null;
  }

  var formdata = new FormData();
  formdata.append("name", name);
  formdata.append("subject", subject);
  formdata.append("message", message);
  formdata.append("email", email);

  var requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_URL}/contact_us_api`, requestOptions);

    if (!response.ok) {
      return 0;
    }
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

const apply_rating = async (id, rating, comment, images) => {
  const token = await get_stored_token();
  if (token === undefined) {
    return null;
  }

  var formdata = new FormData();
  formdata.append("service_id", id);
  formdata.append("rating", rating);
  formdata.append("comment", comment);

  if (images != null) {
    formdata.append("images", images);
  }

  var requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_URL}/add_rating`, requestOptions);

    if (!response.ok) {
      return 0;
    }
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

const change_order_status = async (order_id, status, date, time) => {
  const token = await get_stored_token();
  if (token === undefined) {
    return null;
  }
  var formdata = new FormData();
  formdata.append("order_id", order_id);
  formdata.append("status", status);
  formdata.append("date", date);
  formdata.append("time", time);
  var requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${API_URL}/update_order_status `,
      requestOptions
    );

    if (!response.ok) {
      return 0;
    }
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

const download_invoices = async (order_id) => {
  const token = await get_stored_token();
  if (token === undefined) {
    return null;
  }

  var formdata = new FormData();
  formdata.append("order_id", order_id);

  var requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_URL}/invoice-download`, requestOptions);

    if (!response.ok) {
      return 0;
    }
    return response.blob();
  } catch (error) {
    console.log(error);
  }
};

/*Exporting all Functions for reuseing in differnt components*/
const exp = {
  get_category,
  get_providers,
  get_services,
  get_settings,
  get_home_screen,
  providerAvailable,
  get_bookmarks,
  get_cart,
  AddAddress,
  checkSlots,
  plaecOrder,
  createRazorOrder,
  get_available_slot,
  ManageCart,
  allServices,
  Promocode,
  ValidatePromocode,
  VerifyUser,
  getToken,
  DeleteAddress,
  getAddress,
  bookmark,
  manageUser,
  getSubCategory,
  deleteUserAccount,
  logout,
  getOrders,
  userNotifications,
  getTransaction,
  removeCart,
  getRating,
  add_transactions,
  send_message,
  get_cart_plain,
  update_user,
  apply_rating,
  change_order_status,
  download_invoices,
  getServiceRating,
};
export default exp;
